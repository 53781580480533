/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const reducers = {
  setRanking: (state, action) => {
    state.players = action.payload;
  }
};

export const ranking = createSlice({
  name: 'ranking',
  initialState,
  reducers,
});
