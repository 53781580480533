import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.jpg";
import { login, logout } from "../../redux/actions/session";
import { Link } from "react-router-dom";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../..//components/menuMobile/MenuMobile";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import toast from "react-hot-toast";

const Login = () => {
  const token = useSelector((state) => state.session.token);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    if (!email || !password) {
      return toast.error("Por favor, preencha todos os campos.");
    }
    dispatch(login(email, password));
  }

  return (
    <div className="background">
      <Menu />
      <div className="button-group panel">
        <img className="logo" alt="logo" src={logo}></img>
        <form onSubmit={handleSubmit}>
          <span className="span">
            {!token && (
              <div className="form-panel">
                <div className="container-form">
                  <div className="row">
                    <h2 style={{ textAlign: "center" }}>Fazer login</h2>
                    <div className="input-group input-group-icon">
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="input-icon">
                        <i className="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div className="input-group input-group-icon">
                      <input
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="input-icon">
                        <i className="fa fa-key"></i>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/esqueci-senha" className="link-no-decorator">
                        Esqueceu a senha?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!token && (
              <div className="login-logout">
                <section className="western-button-wrapper">
                  <div className="western-button-container">
                    <button onClick={handleSubmit} className="western-button">
                      Acesse sua Conta
                    </button>
                  </div>
                </section>
                <Link to="/new-account">
                  Ainda não tem uma conta? <br />
                  Criar uma nova conta.
                </Link>
              </div>
            )}
            {token && (
              <section className="western-button-wrapper">
                <div className="western-button-container">
                  <button
                    onClick={() => dispatch(logout())}
                    className="western-button"
                  >
                    Logout
                  </button>
                </div>
              </section>
            )}
          </span>
        </form>
      </div>
      <WhatsAppButton></WhatsAppButton>
      <MobileMenu />
    </div>
  );
};

export default Login;
