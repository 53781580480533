import React from "react";
import PageRoutes from "./pages/PageRoutes";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Auth0Provider } from "@auth0/auth0-react";
import "./assets/styles/styles.scss";

import store from "./redux/store";

function App() {
  return (
    <Auth0Provider>
      <Provider
        store={store}
        domain="dev-g18jsm4n.us.auth0.com"
        clientId="355rTOwsatGI5ApGAau87K8lp7Z1TQ8v"
        redirectUri={window.location.origin}
        audience="https://api.trucoxp.com"
        scope="read:current_user update:current_user_metadata"
      >
        <Toaster />
        <PageRoutes />
      </Provider>
    </Auth0Provider>
  );
}

export default App;
