/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  searching: false,
  modality: 'paulista'
};

const reducers = {
  setQueues: (state, action) => {
    state.list = action.payload;
  },
  startSearching: (state) => {
    state.searching = true;
  },
  stopSearching: (state) => {
    state.searching = false;
  },
  setModality: (state, action) => {
    console.log('action', action)
    state.modality = action.payload;
  },
};

export const queues = createSlice({
  name: 'queues',
  initialState,
  reducers,
});
