import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Profile.scss";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import {
  logout,
  getBalance,
  getPlayersUnderMyAgent,
} from "../../redux/actions/session";
import toast from "react-hot-toast";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import DiscordButton from "../../components/discordButton/DiscordButton";

const Payment = () => {
  const token = useSelector((state) => state.session.token);
  const cellphone = useSelector((state) => state.session.cellphone);
  const cellphoneValidated = useSelector(
    (state) => state.session.cellphoneValidated
  );
  const email = useSelector((state) => state.session.email);
  const nickname = useSelector((state) => state.session.nickname);
  const playersUnderMyAgent = useSelector(
    (state) => state.session.playersUnderMyAgent
  );
  const role = useSelector((state) => state.session.role);
  const score = useSelector((state) => state.session.score);
  const agent_code = useSelector((state) => state.session.agent_code);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("0.00");

  const shouldUpdateProfilePage = useRef(true);

  useEffect(() => {
    if (shouldUpdateProfilePage.current) {
      shouldUpdateProfilePage.current = false;
      dispatch(getBalance());
      if (role === "agent") {
        dispatch(getPlayersUnderMyAgent());
      }
    }
  });

  const copyToClipboard = (e, label) => {
    e.preventDefault();
    navigator.clipboard.writeText(label);
    toast.success("Link de Divulgação do Agente copiado com sucesso!");
  };

  return (
    <>
      <Menu />
      <div className="background profile-page">
        <div className="button-group panel">
          {token && (
            <div>
              <div className="outer-div">
                <div className="inner-div">
                  <div className="front">
                    <div className="front__bkg-photo"></div>
                    <div className="front__face-photo"></div>
                    <div className="form-panel" style={{ marginTop: "73px" }}>
                      <div
                        className="container-form"
                        style={{ background: "unset", width: "100%" }}
                      >
                        <div className="row">
                          <div className="input-group input-group-icon">
                            <input
                              type="text"
                              placeholder="User"
                              value={nickname}
                            />
                            <div className="input-icon">
                              <i className="fa fa-user"></i>
                            </div>
                          </div>
                          <div className="input-group input-group-icon">
                            <input
                              type="email"
                              placeholder="Email"
                              value={email}
                            />
                            <div className="input-icon">
                              <i className="fa fa-envelope"></i>
                            </div>
                          </div>
                          <div className="input-group input-group-icon">
                            <input
                              type="text"
                              placeholder="Phone"
                              value={cellphone}
                            />
                            <div className="input-icon">
                              <i className="fa fa-phone"></i>
                            </div>
                          </div>
                          <div className="input-group input-group-icon">
                            <input
                              type="text"
                              placeholder="Pontos"
                              value={score + " Pontos"}
                            />
                            <div className="input-icon">
                              <i className="fa fa-star"></i>
                            </div>
                          </div>
                          {agent_code && (
                            <>
                              <div className="input-group input-group-icon">
                                <input
                                  type="text"
                                  placeholder="Código de Agente"
                                  value={"Código Agente: " + agent_code}
                                />
                                <div className="input-icon">
                                  <i class="fa-solid fa-address-card"></i>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  backgroundColor: "white",
                                  // height: "50px",
                                  maxWidth: "268px",
                                  color: "black",
                                  padding: "10px",
                                  cursor: "default",
                                }}
                                onClick={(e) =>
                                  copyToClipboard(
                                    e,
                                    `https://trucoxp.com/new-account?agent_code=${agent_code}`
                                  )
                                }
                              >
                                <span style={{ color: "black" }}>
                                  Link de divulgação Agente
                                </span>
                                <br />
                                <span style={{ color: "blue" }}>
                                  {`https://trucoxp.com/new-account?agent_code=${agent_code}`}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {playersUnderMyAgent &&
                Array.isArray(playersUnderMyAgent) &&
                playersUnderMyAgent.length > 0 && (
                  <div className="outer-div">
                    <div className="inner-div">
                      <section
                        className="front"
                        style={{ padding: "20px", marginTop: "20px" }}
                      >
                        <h2 style={{ textAlign: "center" }}>
                          {console.log(
                            "playersUnderMyAgent",
                            playersUnderMyAgent
                          )}
                          Jogadores Cadastrados
                        </h2>
                        <div>
                          {playersUnderMyAgent.map((player) => (
                            <div className="jogador-do-agente">
                              {player.nickname}
                            </div>
                          ))}
                        </div>
                      </section>
                    </div>
                  </div>
                )}
              <section
                className="western-button-wrapper"
                style={{ marginLeft: "0px" }}
              >
                <div className="western-button-container">
                  <button className="western-button backButton">
                    <div
                      href="#"
                      className="backButton"
                      onClick={() => dispatch(logout())}
                    >
                      Sair da conta
                    </div>
                  </button>
                </div>
              </section>
            </div>
          )}
        </div>
        <WhatsAppButton></WhatsAppButton>
      </div>
      <MobileMenu />
    </>
  );
};

export default Payment;
