/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pixQrCode: '',
  pixCopiaCola: '',
  withdrawDashboardData: '',
  amount: '0',
  pixKey: ''
};

const reducers = {
  setPixQrCode: (state, action) => {
    state.pixQrCode = action.payload.qr_code_base64;
    state.pixCopiaCola = action.payload.qr_code;
  },
  setWithdrawDashboardData: (state, action) => {
    state.withdrawDashboardData = action.payload
  },
  setWithdrawPayment: (state, action) => {
    // window.location.reload(false);
  },
  setCarrinhosAbandonados: (state, action) => {
    state.carrinhosAbandonados = action.payload
  },

};

export const payment = createSlice({
  name: 'payment',
  initialState,
  reducers,
});
