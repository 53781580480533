import * as http from "../../utils/requests";
import toast from "react-hot-toast";
import { ranking } from "../reducers/ranking";
export const { setRanking } = ranking.actions;

export const fetchRanking = () => async (dispatch) => {
  try {
    const response = await http.get("ranking");
    dispatch(setRanking(response.data));
  } catch (error) {
    console.error("ERRO", error);
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro");
    }
  }
};
