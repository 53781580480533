import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { leaveQueue } from '../../redux/actions/queues';
import "./Loading.scss";

function Loading() {
  const loadingStatus = useSelector((state) => state.communication.loadingStatus);
  const searching = useSelector((state) => state.queues.searching);

  const dispatch = useDispatch()

  return ( 
    (loadingStatus > 0 || searching) ? 
    <div className={``} id="xp-loader">
      <h2>Procurando partida...</h2>
      <h4>Espera média 30 segundos</h4>
      <div className="loader">
        <div className="inner">
        </div>
      </div>
      <section className="western-button-wrapper">
        <div className="western-button-container">
          <button onClick={() =>  dispatch(leaveQueue())} className="western-button">Cancelar</button>
        </div>
      </section>
    </div>
    : <></>
    
  );
}

export default Loading;
