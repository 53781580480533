import { gameSimulator } from "../reducers/gameSimulator";
import store from "../store";

export const {
  updateGame,
  updateCard,
  updateFlipped,
  setGameId,
  timerDisable,
  timerReset,
  timerTick,
  timerTrucoDisable,
  timerTrucoReset,
  timerTrucoTick,
  setInitialCardState
} = gameSimulator.actions;

export const playCard = (value, suit, player) => async (dispatch) => {
  console.log("value suit", value, suit);
  const { game } = store.getState();
  dispatch(updateCard({ value, suit, player }));
};

export const setInitialCards = ({cards1, cards2}) => async (dispatch) => {
  let formattedCards1 = [];
  let splittedCards1 = cards1.split("");
  formattedCards1.push({value: splittedCards1[0], suit: splittedCards1[1]});
  formattedCards1.push({value: splittedCards1[2], suit: splittedCards1[3]});
  formattedCards1.push({value: splittedCards1[4], suit: splittedCards1[5]});

  let formattedCards2 = [];
  let splittedCards2 = cards2.split("");
  formattedCards2.push({value: splittedCards2[0], suit: splittedCards2[1]});
  formattedCards2.push({value: splittedCards2[2], suit: splittedCards2[3]});
  formattedCards2.push({value: splittedCards2[4], suit: splittedCards2[5]});

  dispatch(setInitialCardState({ cards1: formattedCards1, cards2: formattedCards2 }));
};