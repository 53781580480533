import * as http from "../../utils/requests";
import toast from "react-hot-toast";
import { payment } from "../reducers/payment";
import { getBalance } from './session';
export const { setPixQrCode } = payment.actions;
export const { setWithdrawDashboardData } = payment.actions;
export const { setWithdrawPayment } = payment.actions;
export const { setCarrinhosAbandonados } = payment.actions;

let payments = {};

payments.depositCash = (value) => async (dispatch) => {
  try {
    const response = await http.post("payments", {
      value
    });
    dispatch(setPixQrCode(response.data));
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro");
    }
  }
};

payments.createWithdraw = (amount, pix_key) => async (dispatch) => {
  try {
    const response = await http.post("withdraw", {
      amount, pix_key
    });
    if (response.status === 200) {
      dispatch(getBalance());
      toast.success("Seu saque está sendo processado.")
    }
    return response.status;
  } catch (error) {
    console.error(error);
    toast.error("Erro");
  }
};

payments.fetchWithdrawDashboard = () => async (dispatch) => {
  try {
    const response = await http.get("withdraw?paid=false&limit=50");
    if (response.status === 200) {
      const data = response.data.filter(player => player.paid === false).map(player => {
        return {
          id: player.id,
          username: player.user?.nickname,
          email: player.user?.email,
          phone: player.user?.cellphone,
          value: player.amount,
          pix: player.pix_key
        }
      })
      dispatch(setWithdrawDashboardData(data));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro");
    }
  }
};

payments.withdrawPayment = (id) => async (dispatch) => {
  try {
    const response = await http.put(`withdraw/${id}/paid`, {});
    if (response.status === 200) {
      dispatch(setWithdrawPayment(id));
      window.location.reload(false);
    }
    return response.status;
  } catch (error) {
    console.error(error);
    toast.error("Erro");
  }
};

payments.fetchCarrinhosAbandonados = () => async (dispatch) => {
  try {
    const response = await http.get("payments?limit=200");
    if (response.status === 200) {
      console.log("200", response);
      const data = response.data.filter(player => player.paid === false).map(player => {
        return {
          phone: player.user.cellphone,
        }
      });
      console.log(data);
      dispatch(setCarrinhosAbandonados(data));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro");
    }
  }
};

export default payments;