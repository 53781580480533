import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./MenuMobile.scss";

function MobileMenu() {
  const score = useSelector((state) => state.session.score);
  const balance = useSelector((state) => state.session.balance);
  const token = useSelector((state) => state.session.token);

  const loggedInNav = (
    <nav className="nav-footer-mobile">
      <ul className="upperMenuBar">
        <li>Saldo: R${balance}</li>
        <li>Pontos: {score}</li>
        <li>
          <Link to="/download">Download App</Link>
        </li>
      </ul>
      <ul>
        <li>
          <Link to="/">
            <div className="labelIcon">
              <span>
                <i className="fa-solid fa-house"></i>
              </span>
            </div>
            <div className="labelMenu">
              <p>Início</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/chatApp">
            <div className="labelIcon">
              <span>
                <i className="fa-brands fa-rocketchat"></i>
              </span>
            </div>
            <div className="labelMenu">
              <p>Chat</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/payment">
            <div className="labelIcon">
              <span>
                <i className="fa-solid fa-money-bill-transfer"></i>
              </span>
            </div>
            <div className="labelMenu">
              <p>Depósitos</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/withdraw">
            <div className="labelIcon">
              <span>
                <i className="fa-solid fa-sack-dollar"></i>
              </span>
            </div>
            <div className="labelMenu">
              <p>Saques</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/ranking">
            <div className="labelIcon">
              <span>
                <i className="fa-solid fa-ranking-star"></i>
              </span>
            </div>
            <div className="labelMenu">
              <p>Ranking</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/profile">
            <div className="labelIcon">
              <span>
                <i className="fa-solid fa-user"></i>
              </span>
            </div>
            <div className="labelMenu">
              <p>Perfil</p>
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );

  const loggedOutNav = (
    <>
      <nav className="nav-footer-mobile">
        <ul className="upperMenuBar">
          <li>
            <Link to="/download">Download App</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">
              <div className="labelIcon">
                <span>
                  <i className="fa-solid fa-house"></i>
                </span>
              </div>
              <div className="labelMenu">
                <p>Página Inicial</p>
              </div>
            </Link>
          </li>
          <li style={{ textAlign: "center" }}>
            <Link to="/new-account">
              <button type="button" className="btn btn-warning">
                Cadastrar
              </button>
            </Link>
          </li>
          <li>
            <Link to="/login">
              <div className="labelIcon">
                <span>
                  <i className="fa-solid fa-user"></i>
                </span>
              </div>
              <div className="labelMenu">
                <p>Entrar</p>
              </div>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );

  return <>{token ? loggedInNav : loggedOutNav}</>;
}

export default MobileMenu;
