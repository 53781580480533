import "./WhatsAppButton.scss";
import { useDispatch, useSelector } from "react-redux";
function WhatsAppButton(props) {
  const token = useSelector((state) => state.session.token);
  return (
    <div>
      <div id="whatsapp-link-div">
        <a
          className={`whatsapp-link1 ${!token ? "whatsappLoggedClass" : ""}`}
          href="https://wa.me/message/WDSCDP6MVGOVB1"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-whatsapp"></i>
        </a>
      </div>
    </div>
  );
}

export default WhatsAppButton;
