/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingStatus: 0,
  fetchPlayersInQeueIntervalId: 0
};

const reducers = {
  incrementLoading: (state) => {
    state.loadingStatus += 1;
  },
  decrementLoading: (state) => {
    state.loadingStatus -= 1;
  },
  fetchPlayersInQeueIntervalId: (state, action) => {
    state.fetchPlayersInQeueIntervalId = action.payload;
  }
};

export const communication = createSlice({
  name: 'communication',
  initialState,
  reducers,
});
