import React, { useState, useEffect, useRef } from "react";
import Card from "../../components/cardSimulator/CardSimulator";
import Vira from "../../components/vira/Vira";
import HideCard from "../../components/hideCard/HideCard";
import Scoreboard from "../../components/Scoreboard/Scoreboard";
import { useSelector, useDispatch } from "react-redux";
import { playCard, setInitialCards } from "../../redux/actions/gameSimulator";

const GameSimulator = () => {
  const dispatch = useDispatch();
  const gameSimulator = useSelector((state) => state.gameSimulator);
  const communication = useSelector((state) => state.communication);
  const { p1, p2, next_to_play_id, manilha } = gameSimulator.state;

  const shouldFetch = useRef(true);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const cards1 = queryParameters.get("cards1");
    const cards2 = queryParameters.get("cards2");
    dispatch(setInitialCards({ cards1, cards2 }));
  }, [dispatch]);

  function myTurn() {
    return next_to_play_id === p1.id;
  }

  function shouldHideCard(card) {
    if (
      p1.first_play &&
      p2.first_play &&
      (p1.second_play || p2.second_play) &&
      (card === p1.first_play || card === p2.first_play)
    ) {
      return true;
    }

    if (
      p1.second_play &&
      p2.second_play &&
      (p1.third_play || p2.third_play) &&
      (card === p1.second_play || card === p2.second_play)
    ) {
      return true;
    }

    return false;
  }

  return (
    <div className="App">
      <div className="container">
        <div className="hand rotate oponent">
          <HideCard hide={shouldHideCard(p2.cards[0].value + p2.cards[0].suit)}>
            <Card
              playAction={() =>
                dispatch(playCard(p2.cards[0].value, p2.cards[0].suit, 2))
              }
              value={p2.cards[0].value}
              suit={p2.cards[0].suit}
              flipped={p2.cards[0].flipped}
              played={p2.cards[0].played}
              player={2}
            />
          </HideCard>
          <HideCard hide={shouldHideCard(p2.cards[1].value + p2.cards[1].suit)}>
            <Card
              playAction={() =>
                dispatch(playCard(p2.cards[1].value, p2.cards[1].suit, 2))
              }
              value={p2.cards[1].value}
              suit={p2.cards[1].suit}
              flipped={p2.cards[1].flipped}
              played={p2.cards[1].played}
              player={2}
            />
          </HideCard>
          <HideCard hide={shouldHideCard(p2.cards[2].value + p2.cards[2].suit)}>
            <Card
              playAction={() =>
                dispatch(playCard(p2.cards[2].value, p2.cards[2].suit, 2))
              }
              value={p2.cards[2].value}
              suit={p2.cards[2].suit}
              flipped={p2.cards[2].flipped}
              played={p2.cards[2].played}
              player={2}
            />
          </HideCard>
        </div>
        <div className="table">
          <Vira suit={manilha.suit} value={manilha.value}></Vira>
          <div className="deck card">
            <div className="card__back"></div>
          </div>
        </div>
        <div className={`hand ${myTurn() ? "yourHand" : ""}`}>
          <HideCard hide={shouldHideCard(p1.cards[0].value + p1.cards[0].suit)}>
            <Card
              playAction={() =>
                dispatch(playCard(p1.cards[0].value, p1.cards[0].suit, 1))
              }
              value={p1.cards[0].value}
              suit={p1.cards[0].suit}
              flipped={p1.cards[0].flipped}
              played={p1.cards[0].played}
              player={1}
            />
          </HideCard>
          <HideCard hide={shouldHideCard(p1.cards[1].value + p1.cards[1].suit)}>
            <Card
              playAction={() =>
                dispatch(playCard(p1.cards[1].value, p1.cards[1].suit, 1))
              }
              value={p1.cards[1].value}
              suit={p1.cards[1].suit}
              flipped={p1.cards[1].flipped}
              played={p1.cards[1].played}
              player={1}
            />
          </HideCard>
          <HideCard hide={shouldHideCard(p1.cards[2].value + p1.cards[2].suit)}>
            <Card
              playAction={() =>
                dispatch(playCard(p1.cards[2].value, p1.cards[2].suit, 1))
              }
              value={p1.cards[2].value}
              suit={p1.cards[2].suit}
              flipped={p1.cards[2].flipped}
              played={p1.cards[2].played}
              player={1}
            />
          </HideCard>
        </div>
      </div>
    </div>
  );
};

export default GameSimulator;
