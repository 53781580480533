import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from 'history';

import { communication } from "./reducers/communication";
import { game } from "./reducers/game";
import { queues } from "./reducers/queues";
import { session } from "./reducers/session";
import { payment } from "./reducers/payment";
import { ranking } from "./reducers/ranking";
import { gameSimulator } from "./reducers/gameSimulator";

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory(), savePreviousLocations: 1 });

const middleware = [routerMiddleware];

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    communication: communication.reducer,
    game: game.reducer,
    gameSimulator: gameSimulator.reducer,
    queues: queues.reducer,
    session: session.reducer,
    payment: payment.reducer,
    ranking: ranking.reducer
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const history = createReduxHistory(store);

export default store;
