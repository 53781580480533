import React, { useState } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import ChatApp from "../../pages/chatApp/ChatApp"; // Ajuste o caminho conforme necessário
import "./SideButtonDrag.scss"; // Insira o nome do seu arquivo CSS

const SideButtonDrag = () => {
  const [isChatAppVisible, setIsChatAppVisible] = useState(true);

  const toggleChatApp = () => {
    setIsChatAppVisible(!isChatAppVisible);
  };

  return (
    <div>
      <div className="fixed-button-container">
        <Row>
          <Col>
            <Button color="primary" onClick={toggleChatApp} size="lg">
              <FontAwesomeIcon icon={faCommentDots} />
            </Button>
          </Col>
        </Row>
      </div>

      {isChatAppVisible && (
        <div className="chat-app-container">
          <ChatApp />
        </div>
      )}
    </div>
  );
};

export default SideButtonDrag;
