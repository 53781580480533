import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.jpg";
import payments from "../../redux/actions/payments";
import "./Payment.scss";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import { getBalance } from "../../redux/actions/session";
import toast from "react-hot-toast";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import CurrencyInput from "../currencyInput/CurrencyInput";

const Payment = () => {
  const token = useSelector((state) => state.session.token);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");

  useEffect(() => {
    dispatch(getBalance());
  });

  async function handleSubmit(e) {
    e.preventDefault();
    let formattedAmount = amount.replace(",", ".");
    formattedAmount = amount.trim().replace("R$", "");

    // Crie o evento de dados
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'revenue': formattedAmount,
          }
        }
      }
    });
    dispatch(payments.depositCash(formattedAmount));
  }

  const getQrCode = () => {
    const src = `data:image/jpeg;base64,${payment.pixQrCode}`;
    return (
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <img src={src} alt="QR Code" width="200px" height="200px" />
      </div>
    );
  };

  const copyToClipboard = (e, label) => {
    e.preventDefault();
    navigator.clipboard.writeText(label);
    toast.success("Código Pix Copiado com Sucesso");
  };

  return (
    <>
      <Menu />
      <div className="background">
        <div className="button-group panel">
          <img className="logo" alt="logo" src={logo}></img>
          <form onSubmit={handleSubmit}>
            <span className="span">
              {token && !payment.pixQrCode && (
                <div className="form-panel">
                  <div className="container-form">
                    <div className="row">
                      <h2 style={{ textAlign: "center" }}>
                        Quanto quer depositar?
                      </h2>
                      <div className="input-group input-group-icon">
                        <CurrencyInput amount={amount} setAmount={setAmount} />
                        <div className="input-icon">
                          <i className="fa-solid fa-money-bill-transfer"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {token && !payment.pixQrCode && (
                <div className="login-logout">
                  <section className="western-button-wrapper">
                    <div className="western-button-container">
                      <button onClick={handleSubmit} className="western-button">
                        Depositar
                      </button>
                    </div>
                  </section>
                </div>
              )}
            </span>
          </form>
          {payment.pixQrCode && getQrCode()}
          {token && payment.pixQrCode && (
            <>
              <div className="pix-copia-cola">
                <p
                  style={{ color: "white", width: "300px", fontWeight: "600" }}
                >
                  Pix Copia e Cola:
                </p>
                <p
                  style={{
                    color: "white",
                    width: "300px",
                    fontSize: "12px",
                    wordWrap: "break-word",
                  }}
                >
                  {payment.pixCopiaCola}
                </p>
                <button
                  onClick={(e) => copyToClipboard(e, payment.pixCopiaCola)}
                >
                  Copiar Código Pix
                </button>
              </div>
              <p style={{ color: "white", width: "300px" }}>
                Após fazer seu pagamento, seu saldo irá ser depositado
                automaticamente. Qualquer dúvida, mande mensagem no nosso
                WhatsApp!
              </p>
            </>
          )}
        </div>
        <WhatsAppButton></WhatsAppButton>
      </div>
      <MobileMenu />
    </>
  );
};

export default Payment;
