import { useEffect } from "react";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logo.jpg";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import { unsubscribe } from "../../redux/actions/session";

const Unsubscribe = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unsubscribe());
  }, [dispatch]);

  return (
    <div className="background">
      <Menu />
      <div className="button-group panel">
        <img className="logo" alt="logo" src={logo}></img>
        <div style={{ color: "white" }}>
          Você se desinscreveu da lista de e-mails da Truco XP com sucesso.
        </div>
      </div>
      <WhatsAppButton></WhatsAppButton>
      <MobileMenu />
    </div>
  );
};

export default Unsubscribe;
