import "./DiscordButton.scss";
import discordImg from "../../assets/images/discord.jpg";

function DiscordButton() {
  return ( 
    <div className="discord-button">
      <a href="https://discord.gg/n4gCPzefHW" target="_blank" rel="noreferrer"><img src={discordImg} alt="Link para o Discord do Truco XP"></img></a>
    </div>
  );
}

export default DiscordButton;
