import { MILISECONDS_TO_FETCH_GAME_STATE } from "../../utils/constants";
import toast from "react-hot-toast";
import { push } from "redux-first-history";
import * as http from "../../utils/requests";
import { queues } from "../reducers/queues";
import { game } from "../reducers/game";
import store from "../store";

export const { setQueues, startSearching, stopSearching, setModality } = queues.actions;
export const { setGameId } = game.actions;

export const fetchQueues = () => async (dispatch, getState) => {
  const modality = getState().queues.modality;
  try {
    const response = await http.get(`queues?modality=${modality}`);
    if (response.status === 200) {
      dispatch(setQueues(response.data));
      dispatch(setModality(modality)); 
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast("Erro ao recuperar as filas de espera.");
    }
  }
};

export const checkQueues = () => async (dispatch) => {
  const searching = store.getState().queues.searching;
  try {
    const response = await http.get("notifications");
    if (response.status === 200 && response.data.length) {
      const notification = response.data.find(
        (notification) => notification.name === "match_found"
      );

      if (notification) {
        const gameId = JSON.parse(notification.payload_json).game_id;
        dispatch(stopSearching());
        dispatch(setGameId(gameId));
        dispatch(push("/game"));
      } else if (searching) {
        setTimeout(
          () => dispatch(checkQueues()),
          MILISECONDS_TO_FETCH_GAME_STATE
        );
      }
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast("Erro ao procurar jogo.");
    }
  }
};

export const joinQueue = (id) => async (dispatch) => {
  try {
    const response = await http.post(`queues/${id}/enter`);
    if (response.status === 200) {
      dispatch(startSearching());
      dispatch(checkQueues());
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    }
  }
};

export const leaveQueue = () => async (dispatch) => {
  try {
    const response = await http.post(`queues/leave`);
    if (response.status === 200) {
      dispatch(stopSearching());
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      if (error.response && error.response.data) {
        toast.error(error.response.data.detail);
      } else {
        toast("Erro ao sair da fila. Por favor tente novamente.");
      }
    }
  }
};
