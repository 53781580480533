import React from "react";
import toast from 'react-hot-toast';

const manilha = {
  "4": "o cinco",
  "5": "o Seis",
  "6": "o Sete",
  "7": "a Dama",
  "Q": "o Valete",
  "J": "o Rei",
  "K": "o Ás",
  "A": "o Dois",
  "2": "o Três",
  "3": "o Quatro",
}

const Vira = (props) => {
  const { value, suit } = props;

  return (
    <div
      onClick={() => toast(`Quem manda é ${manilha[value]}`)}
      className={`manilha hand__card card card--${suit
        } hand__card--flipped`}
    >
      <div className="card__face">
        <div
          data-value={value}
          data-suit={suit}
          className="card__value"
        ></div>
        <div
          data-value={value}
          data-suit=""
          className="card__value"
        ></div>
        <div className="card__suit">{suit}</div>
      </div>
      <div className="card__back"></div>
    </div>
  );
};

export default Vira;
