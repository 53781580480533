import toast from "react-hot-toast";
import { communication } from '../reducers/communication';

export const {
  incrementLoading,
  decrementLoading,
  fetchPlayersInQeueIntervalId
} = communication.actions;

export const setFetchQeueIntervalId = (id) => async (dispatch) => {
  try {
    dispatch(fetchPlayersInQeueIntervalId(id));
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro");
    }
  }
};