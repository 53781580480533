import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.jpg";
import { recoverPasswordSetNewPassword } from "../../redux/actions/session";
import { Link } from "react-router-dom";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import toast from "react-hot-toast";

const RedefinirSenha = () => {
  const token = useSelector((state) => state.session.token);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailFromQuery, setEmailFromQuery] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const queryEmail = queryParameters.get("email");
    setEmailFromQuery(queryEmail);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (emailFromQuery) {
      if (!newPassword || !code) {
        return toast.error("Por favor, preencha todos os campos.");
      }
    } else if (!email || !newPassword || !code) {
      return toast.error("Por favor, preencha todos os campos.");
    }
    dispatch(
      recoverPasswordSetNewPassword(emailFromQuery || email, code, newPassword)
    );
  }

  return (
    <div className="background">
      <Menu />
      <div className="button-group panel">
        <img className="logo" alt="logo" src={logo}></img>
        <form onSubmit={handleSubmit}>
          <span className="span">
            {!token && (
              <div className="form-panel">
                <div className="container-form">
                  <div className="row">
                    <h2 style={{ textAlign: "center" }}>
                      Você recebeu um código por Email. Preencha nos campos
                      abaixo para redefinir sua senha
                    </h2>
                    {!emailFromQuery && (
                      <div className="input-group input-group-icon">
                        <input
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="input-icon">
                          <i className="fa fa-envelope"></i>
                        </div>
                      </div>
                    )}

                    <div className="input-group input-group-icon">
                      <input
                        type="text"
                        placeholder="Código recebido"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                      <div className="input-icon">
                        <i className="fa-solid fa-calculator"></i>
                      </div>
                    </div>
                    <div className="input-group input-group-icon">
                      <input
                        type="email"
                        placeholder="Nova Senha"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <div className="input-icon">
                        <i className="fa fa-key"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!token && (
              <div className="login-logout">
                <section className="western-button-wrapper">
                  <div className="western-button-container">
                    <button onClick={handleSubmit} className="western-button">
                      Redefinir Senha
                    </button>
                  </div>
                </section>
                <Link to="/new-account">
                  Ainda não tem uma conta? <br />
                  Criar uma nova conta.
                </Link>
              </div>
            )}
          </span>
        </form>
      </div>
      <WhatsAppButton></WhatsAppButton>
      <MobileMenu />
    </div>
  );
};

export default RedefinirSenha;
