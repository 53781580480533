import React from "react";
import "./footerPrivacidade.scss";

const FooterPrivacidade = () => {
  return (
    <footer className="footer-privacidade">
      <div className="item-footer">
        <a
          href="/termos-e-condicoes.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Termos de Uso
        </a>
      </div>
      <span> | </span>
      <div className="item-footer">
        <a
          href="/politica-de-privacidade.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </a>
      </div>
    </footer>
  );
};

export default FooterPrivacidade;
