import React, { useState, useRef } from "react";

function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "");
}

function formatCurrency(inputValue, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // don't validate empty input
  if (inputValue === "") {
    return "";
  }

  // check for decimal
  if (inputValue.indexOf(".") >= 0) {
    // split number by decimal point
    const [leftSide, rightSide] = inputValue.split(".");

    // add commas to left side of number
    const formattedLeftSide = formatNumber(leftSide);

    // validate right side
    const formattedRightSide = formatNumber(rightSide);

    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      return `R$${formattedLeftSide}.${formattedRightSide}`;
    }

    // Limit decimal to only 2 digits
    const limitedRightSide = formattedRightSide.substring(0, 2);

    // join number by .
    return `R$${formattedLeftSide}.${limitedRightSide}`;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    const formattedValue = formatNumber(inputValue);
    const result = `R$${formattedValue}`;

    // final formatting
    if (blur === "blur") {
      return `${result}.00`;
    }

    return result;
  }
}

function CurrencyInput({ defaultValue, amount, setAmount }) {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const inputRef = useRef(null);

  function handleKeyUp() {
    setAmount(formatCurrency(inputRef.current.value));
  }

  function handleBlur() {
    setAmount(formatCurrency(inputRef.current.value, "blur"));
  }

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="R$ 0.00"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
    />
  );
}

export default CurrencyInput;
