import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timer: undefined,
  timerTruco: undefined,
  rounds: {
    list: [],
  },
  state: {
    id: null,
    next_to_play_id: null,
    p1: {
      id: null,
      name: "",
      game_score: 0,
      round_score: 0,
      cards: [{ value: null, suit: null, flipped: true, played: false }, { value: null, suit: null, flipped: true, played: false }, { value: null, suit: null, flipped: true, played: false }],
      first_play: null,
      second_play: null,
      third_play:null,
    },
    p2: {
      id: null,
      name: "",
      game_score: 0,
      round_score: 0,
      cards: [{ value: null, suit: null, flipped: true, played: false }, { value: null, suit: null, flipped: true, played: false }, { value: null, suit: null, flipped: true, played: false }],
      first_play: null,
      second_play: null,
      third_play:null,
    }, 
    manilha: { value: null, suit: null },
    round_points: 1,
    first_round_winner: null,
    truco_challenger_id: null,
    truco_challenge_running: 0,
    round_winner: null,
  },
};

const reducers = {
  updateGame: (state, action) => {
    state.state = action.payload;
  },
  updateCard: (state, action) => {
    const { value, suit } = action.payload; 
    const index = state.state.p1.cards.findIndex((card) => (card.value === value && card.suit === suit));
    state.state.p1.cards[index] = {...state.state.p1.cards[index], played: true};
  },
  updateFlipped: (state) => {
    state.flipped = !state.flipped;
  },
  setGameId: (state, action) => {
    state.id = action.payload;
  },
  timerReset: (state) => {
    state.timer = 20;
  },
  timerDisable: (state) => {
    state.timer = '--';
  },
  timerTick: (state) => {
    state.timer = state.timer - 1;
  },
  timerTrucoReset: (state) => {
    state.timerTruco = 15;
  },
  timerTrucoDisable: (state, action) => {
    state.timerTruco = action.payload;
  },
  timerTrucoTick: (state) => {
    state.timerTruco = state.timerTruco - 1;
  },
};

export const game = createSlice({
  name: 'game',
  initialState,
  reducers,
});
