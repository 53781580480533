import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./MenuDesktop.scss";
import { Link } from "react-router-dom";
import "../btn-bootstrap/btn-bootstrap.scss";

function Loading() {
  const score = useSelector((state) => state.session.score);
  const balance = useSelector((state) => state.session.balance);
  const token = useSelector((state) => state.session.token);

  return (
    <div className="menuDesktop">
      <div className="menuBackground"></div>
      {token ? (
        <>
          <Link to="/">
            <span className="paginaInicial">
              <span className="title">Pagina Inicial</span>
            </span>
          </Link>
          <Link to="/profile">
            <span className="profileItemMenu">
              <span className="title">Perfil</span>
            </span>
          </Link>
          <Link to="/payment">
            <span className="depositMoney">
              <span className="title">Depositar</span>
            </span>
          </Link>
          <Link to="/withdraw">
            <span className="withdrawMoney">
              <span className="title">Sacar</span>
            </span>
          </Link>
          <Link to="/ranking">
            <span className="rankingItemMenu">
              <span className="title">Ranking</span>
            </span>
          </Link>
          <span className="scoreItemMenu">
            <span className="title">Pontos: </span>
            <span className="value">{score}</span>
          </span>
          <span className="moneyItemMenu">
            <span className="title">Saldo: </span>
            <span className="value">{balance}</span>
          </span>
        </>
      ) : (
        <>
          <Link to="/">
            <span
              className="paginaInicial"
              style={{ left: "0px", right: "unset" }}
            >
              <span className="title">Pagina Inicial</span>
            </span>
          </Link>
          <Link to="/new-account">
            <span className="paginaInicial" style={{ right: "120px" }}>
              <button type="button" className="btn btn-warning">
                Cadastrar
              </button>
            </span>
          </Link>
          <Link to="/login">
            <span className="rankingItemMenu" style={{ right: "0px" }}>
              <span className="title">Entrar</span>
            </span>
          </Link>
        </>
      )}
    </div>
  );
}

export default Loading;
