import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createAccount } from "../../redux/actions/session";
import Menu from "../../components/menuDesktop/MenuDesktop";
import { Link } from "react-router-dom";
import MobileMenu from "../..//components/menuMobile/MenuMobile";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";
import "./NewAccount.scss";

const NewAccount = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [password, setPassword] = useState("");
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [promo, setPromo] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [nicknameError, setNicknameError] = useState("");
  const [cellphoneError, setCellphoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [agent, setAgent] = useState(false);
  const [agentCode, setAgentCode] = useState("");
  const [urlAgentCode, setUrlAgentCode] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const agentCode = queryParameters.get("agent_code");
    setUrlAgentCode(agentCode);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (!termsOfUse) {
      return toast.error("Aceite os termos de uso antes de continuar");
    } else if (emailError || nicknameError || cellphoneError || passwordError) {
      return toast.error(
        "Preencha todos os campos corretamente antes de continuar"
      );
    }
    let phoneRaw = cellphone.replace(/[^0-9]/g, "");
    dispatch(
      createAccount({
        email,
        nickname,
        cellphone: phoneRaw,
        password,
        agentCode: agentCode || urlAgentCode || "",
      })
    );
  }

  function handleSliderChange(event) {
    setAgent(event.target.checked);
    if (!event.target.checked) {
      setAgentCode("");
    }
    console.log("agent code", agentCode);
  }

  function validateNickname() {
    if (nickname.trim().length === 0) {
      setNicknameError("Campo obrigatório");
    } else if (nickname.trim().length > 10) {
      setNicknameError(
        "O Nome de Usuário deve possuir no máximo 10 caracteres"
      );
    } else {
      setNicknameError("");
    }
  }

  function validateCellphone() {
    let phoneRaw = cellphone.replace(/[^0-9]/g, "");
    console.log("validate");
    if (phoneRaw.length === 0) {
      setCellphoneError("Campo obrigatório");
    } else if (phoneRaw.length !== 11) {
      setCellphoneError("Número inválido");
    } else {
      setCellphoneError("");
    }
  }

  function validateEmail() {
    if (email.trim().length === 0) {
      setEmailError("Campo obrigatório");
    } else if (!email.trim().match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
      setEmailError("Email inválido");
    } else {
      setEmailError("");
    }
  }

  function validatePassword() {
    if (password.length === 0) {
      setPasswordError("Campo obrigatório");
    } else if (password.length < 8) {
      setPasswordError("A Senha deve ter pelo pelos 7 caracteres");
    } else {
      setPasswordError("");
    }
  }

  return (
    <div className="background">
      <Menu />
      <div className="button-group panel signUp">
        <form onSubmit={handleSubmit}>
          <span className="span">
            <div className="form-panel">
              <div className="container-form">
                <div className="row">
                  <h2 style={{ textAlign: "center" }}>Criar Nova Conta</h2>
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      placeholder="Nome de Usuário"
                      value={nickname}
                      onChange={(e) => setNickname(e.target.value)}
                      onBlur={() => validateNickname()}
                      onFocus={() => {
                        setNicknameError("");
                        setNickname(nickname.trim());
                      }}
                    />
                    <div className="input-icon">
                      <i className="fa fa-user"></i>
                    </div>
                    {nicknameError && (
                      <span style={{ color: "red", maxWidth: "340px" }}>
                        {nicknameError}
                      </span>
                    )}
                  </div>
                  <div className="input-group input-group-icon">
                    <InputMask
                      name="cpf"
                      mask="(99)99999-9999"
                      value={cellphone}
                      placeholder="Celular"
                      onChange={(e) => setCellphone(e.target.value)}
                      onBlur={() => validateCellphone()}
                      onFocus={() => {
                        setCellphoneError("");
                        setCellphone(cellphone);
                      }}
                      inputMode="numeric"
                    />
                    <div className="input-icon">
                      <i className="fa fa-phone"></i>
                    </div>
                    {cellphoneError && (
                      <span style={{ color: "red" }}>{cellphoneError}</span>
                    )}
                  </div>
                  <div className="input-group input-group-icon">
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => validateEmail()}
                      onFocus={() => {
                        setEmailError("");
                        setEmail(email.trim());
                      }}
                    />
                    <div className="input-icon">
                      <i className="fa fa-envelope"></i>
                    </div>
                    {emailError && (
                      <span style={{ color: "red" }}>{emailError}</span>
                    )}
                  </div>
                  <div className="input-group input-group-icon">
                    <input
                      type="password"
                      placeholder="Senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={() => validatePassword()}
                      onFocus={() => {
                        setPasswordError("");
                      }}
                    />
                    <div className="input-icon">
                      <i className="fa fa-key"></i>
                    </div>
                    {passwordError && (
                      <span style={{ color: "red" }}>{passwordError}</span>
                    )}
                  </div>

                  {!urlAgentCode && (
                    <div className="slider-wrapper">
                      <label className="toggle-switch">
                        <input type="checkbox" onChange={handleSliderChange} />
                        <span className="slider round"></span>
                      </label>
                      <span style={{ color: "white" }}>Possui agente?</span>
                    </div>
                  )}

                  {agent && (
                    <div className="input-group input-group-icon">
                      <input
                        type="text"
                        placeholder="Código do Agente"
                        value={agentCode}
                        onChange={(e) => setAgentCode(e.target.value)}
                      />
                      <div className="input-icon">
                        <i className="fa-solid fa-address-card"></i>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div
                    className="input-group"
                    style={{ marginBottom: "4px", fontSize: "13px" }}
                  >
                    <input
                      id="promo"
                      type="checkbox"
                      onChange={() => {
                        setPromo(!promo);
                      }}
                    />
                    <label for="promo" style={{ color: "white" }}>
                      Aceito receber notificações promocionais
                    </label>
                  </div>
                  <div
                    className="input-group"
                    style={{ marginBottom: "2px", fontSize: "13px" }}
                  >
                    <input
                      id="terms"
                      type="checkbox"
                      onChange={() => {
                        setTermsOfUse(!termsOfUse);
                      }}
                    />
                    <label for="terms" style={{ color: "white" }}>
                      Eu aceito os Termos de Uso e tenho mais de 18 anos.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <section className="western-button-wrapper">
              <div className="western-button-container">
                <button onClick={handleSubmit} className="western-button">
                  Criar Conta
                </button>
              </div>
            </section>
            <Link to="/login">
              <p
                style={{
                  color: "white",
                  textDecoration: "underline",
                  textAlign: "center",
                }}
              >
                Já tem uma conta? <br />
                Clique aqui para ENTRAR
              </p>
            </Link>
          </span>
        </form>
      </div>
      <WhatsAppButton></WhatsAppButton>
      <MobileMenu />
    </div>
  );
};

export default NewAccount;
