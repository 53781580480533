/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") || null,
  balance: 0,
  agent_code: null,
  playersUnderMyAgent: null,
};

const reducers = {
  setToken: (state, action) => {
    state.token = action.payload;
    localStorage.setItem("token", action.payload);
  },
  removeToken: (state) => {
    state.token = null;
    localStorage.removeItem("token");
  },
  setBalance: (state, action) => {
    state.balance = action.payload;
  },
  setQueueId: (state, action) => {
    state.queueId = action.payload;
  },
  setScore: (state, action) => {
    state.score = action.payload;
  },
  setCellphone: (state, action) => {
    state.cellphone = action.payload;
  },
  setCellphoneValidated: (state, action) => {
    state.cellphone_validated = action.payload;
  },
  setEmail: (state, action) => {
    state.email = action.payload;
  },
  setNickname: (state, action) => {
    state.nickname = action.payload;
  },
  setAgentCode: (state, action) => {
    state.agent_code = action.payload;
  },
  setPlayersUnderMyAgent: (state, action) => {
    state.playersUnderMyAgent = action.payload;
  },
};

export const session = createSlice({
  name: "session",
  initialState,
  reducers,
});
