import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import Menu from "../../components/menuDesktop/MenuDesktop";
import { getBalance } from "../../redux/actions/session";
import "./ChatApp.scss";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import avatar1 from "../../assets/images/avatar/avatar1.jpg";
import avatar2 from "../../assets/images/avatar/avatar2.jpg";
import avatar3 from "../../assets/images/avatar/avatar3.jpg";
import avatar4 from "../../assets/images/avatar/avatar4.jpg";
import avatar5 from "../../assets/images/avatar/avatar5.jpg";
import avatar6 from "../../assets/images/avatar/avatar6.jpg";
import avatar7 from "../../assets/images/avatar/avatar7.jpg";
import avatar8 from "../../assets/images/avatar/avatar8.jpg";
import avatar9 from "../../assets/images/avatar/avatar9.jpg";
import avatar10 from "../../assets/images/avatar/avatar10.jpg";
import avatar11 from "../../assets/images/avatar/avatar11.jpg";
import avatar12 from "../../assets/images/avatar/avatar12.jpg";
import avatar13 from "../../assets/images/avatar/avatar13.jpg";
import avatar14 from "../../assets/images/avatar/avatar14.jpg";
import avatar15 from "../../assets/images/avatar/avatar15.jpg";
import avatar16 from "../../assets/images/avatar/avatar16.jpg";
import avatar17 from "../../assets/images/avatar/avatar17.jpg";
import avatar18 from "../../assets/images/avatar/avatar18.jpg";
import avatar19 from "../../assets/images/avatar/avatar19.jpg";
import avatar20 from "../../assets/images/avatar/avatar20.jpg";
import avatar21 from "../../assets/images/avatar/avatar21.jpg";
import avatar22 from "../../assets/images/avatar/avatar22.jpg";
import avatar23 from "../../assets/images/avatar/avatar23.jpg";
import avatar24 from "../../assets/images/avatar/avatar24.jpg";

const getAvatar = (author) => {
  const authorName = author?.nickname || author || "Test";

  const index = authorName
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const rest = index % 24;
  if (rest === 0) return avatar1;
  if (rest === 1) return avatar2;
  if (rest === 2) return avatar3;
  if (rest === 3) return avatar4;
  if (rest === 4) return avatar5;
  if (rest === 5) return avatar6;
  if (rest === 6) return avatar7;
  if (rest === 7) return avatar8;
  if (rest === 8) return avatar9;
  if (rest === 9) return avatar10;
  if (rest === 10) return avatar11;
  if (rest === 11) return avatar12;
  if (rest === 12) return avatar13;
  if (rest === 13) return avatar14;
  if (rest === 14) return avatar15;
  if (rest === 15) return avatar16;
  if (rest === 16) return avatar17;
  if (rest === 17) return avatar18;
  if (rest === 18) return avatar19;
  if (rest === 19) return avatar20;
  if (rest === 20) return avatar21;
  if (rest === 21) return avatar22;
  if (rest === 22) return avatar23;
  if (rest === 23) return avatar24;
};

function formatTimestamp(timestamp) {
  const date = timestamp?.toDate();
  const hours = date?.getHours();
  const minutes = date?.getMinutes()?.toString()?.padStart(2, "0");
  return `${hours}:${minutes}`;
}

firebase.initializeApp({
  apiKey:
    process.env.FIREBASE_APY_KEY || "AIzaSyCDPcuL4qP8qAcg9tbHinHOA3onqKg6Xas",
  authDomain:
    process.env.FIREBASE_AUTH_DOMAIN || "real-time-chat-1a143.firebaseapp.com",
  projectId: process.env.FIREBASE_PROJECT_ID || "real-time-chat-1a143",
  storageBucket:
    process.env.FIREBASE_STORAGE_BUCKET || "real-time-chat-1a143.appspot.com",
  messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID || "340862776213",
  appId:
    process.env.FIREBASE_APP_ID || "1:340862776213:web:2741eda4837d6d5ef5fc9c",
  measurementId: process.env.FIREBASE_MEASUREMENT_ID || "G-4DLMDZ8NCJ",
});

const firestore = firebase.firestore();

function ChatApp() {
  const dispatch = useDispatch();
  const shouldFetch = useRef(true);
  const nickname = useSelector((state) => state?.session?.nickname);
  const token = useSelector((state) => state.session.token);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;

      if (token) {
        dispatch(getBalance());
      }
    }
  });

  return (
    <>
      <Menu />
      <div className="chat-container">
        <div className="chat-app">
          <section>
            <ChatRoom nickname={nickname} />
          </section>
        </div>
        <MobileMenu />
      </div>
    </>
  );
}

function ChatRoom({ nickname }) {
  const dummy = useRef();
  const messagesRef = firestore.collection("messages");
  const query = messagesRef.orderBy("createdAt", "desc").limit(20);

  const [messages] = useCollectionData(query, { idField: "id" });

  const [formValue, setFormValue] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      author: nickname,
    });

    setFormValue("");
    dummy?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    dummy?.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <main>
        {messages &&
          messages
            .slice(0)
            .reverse()
            .map((msg, index) => <ChatMessage key={index} message={msg} />)}

        <span ref={dummy}></span>
      </main>

      <form onSubmit={sendMessage}>
        <input
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Digite sua mensagem..."
        />

        <button type="submit" disabled={!formValue}>
          Enviar
        </button>
      </form>
    </>
  );
}

function ChatMessage(props) {
  const { text, author, createdAt } = props.message;

  const messageClass = "sent";
  const formattedTime = formatTimestamp(createdAt);

  return (
    <>
      <div
        className={`message ${messageClass}`}
        style={{
          display: "flex",
          alignItems: "flex-start",
          margin: "10px",
          background: "#2d2e30",
        }}
      >
        <img
          style={{ marginRight: "12px" }}
          src={getAvatar(author)}
          alt="profile"
        />
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <b>{author?.nickname || author || "Anônimo"}</b>
            </p>
            <p style={{ fontSize: "0.8rem", color: "#a0a0a0" }}>
              {formattedTime}
            </p>
          </div>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
}

export default ChatApp;
