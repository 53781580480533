import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CarrinhosAbandonados.scss";
import payments from "../../redux/actions/payments";

const WithdrawDashboard = () => {
  const token = useSelector((state) => state.session.token);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect");
    dispatch(payments.fetchCarrinhosAbandonados());
  }, [dispatch]);

  const data = useSelector((state) => {
    return state.payment;
  });

  return (
    <>
      {console.log("the data is", data)}
      <div
        id="carrinhos-abandonados"
        className="carrinhos-abandonados"
        style={{
          width: "80%",
          height: "auto",
          backgroundColor: "white",
          margin: "100px",
        }}
      >
        <h1 style={{ color: "Black" }}>Carrinhos Abandonados</h1>
        {data &&
          data.carrinhosAbandonados &&
          data.carrinhosAbandonados.map((player, index) => (
            <p>{"+55" + player.phone + ","}</p>
          ))}
      </div>
    </>
  );
};

export default WithdrawDashboard;
