import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const Card = (props) => {
  const dispatch = useDispatch();
  const gameSimulator = useSelector((state) => state.gameSimulator);
  const { value, suit, played, flipped, playAction, player } = props;
  const [zIndexP1, setZIndexP1] = useState(1);

  useEffect(() => {
    let rightPlayer;
    if (player === 1) {
      rightPlayer = gameSimulator?.state.p1;
    } else if (player === 2) {
      rightPlayer = gameSimulator?.state.p2;
    }
    if (rightPlayer.first_play == `${value}${suit}`) {
      setZIndexP1(10);
    }
    if (rightPlayer.second_play == `${value}${suit}`) {
      setZIndexP1(20);
    }
    if (rightPlayer.third_play == `${value}${suit}`) {
      setZIndexP1(30);
    }
  }, [dispatch, playAction]);

  const playFunc = () => {
    playAction();
  };

  return (
    <div
      onClick={playFunc}
      className={`hand__card card card--${suit} hand__card--${
        played ? "leave" : "flipped"
      } ${flipped ? "hidden" : ""}`}
      style={{ zIndex: zIndexP1 }}
    >
      <div className="card__face playerCards">
        <div data-value={value} data-suit={suit} className="card__value"></div>
        <div data-value={value} data-suit="" className="card__value"></div>
        <div className="card__suit">{suit}</div>
      </div>
      <div className="card__back"></div>
    </div>
  );
};

export default Card;
