import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.jpg";
import payments from "../../redux/actions/payments";
import { getBalance, getNotifications } from "../../redux/actions/session";
import "./WithdrawClient.scss";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import PowerButton from "../../components/powerButton/PowerButton";
import { logout } from "../../redux/actions/session";
import toast from "react-hot-toast";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import DiscordButton from "../../components/discordButton/DiscordButton";
import CurrencyInput from "../currencyInput/CurrencyInput";

const WithdrawClient = () => {
  const token = useSelector((state) => state.session.token);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("0.00");
  const [pixKey, setPixKey] = useState("");
  const [status, setStatus] = useState("");
  const [amountError, setAmountError] = useState("");
  const [pixKeyError, setPixKeyError] = useState("");

  useEffect(() => {
    dispatch(getBalance());
    dispatch(getNotifications());
  });

  async function handleSubmit(e) {
    e.preventDefault();
    let formattedAmount = amount.replace(",", ".");
    formattedAmount = amount.trim().replace("R$", "");

    if (formattedAmount <= 0.1) {
      setAmountError("O valor do saque tem que ser maior que  R$ 0.10");
    } else {
      setAmountError("");
    }

    if (!pixKey) {
      return setPixKeyError(
        "Insira sua chave Pix em que deseja receber o saque."
      );
    } else if (pixKey.length < 5) {
      return setPixKeyError("Chave Pix inválida. Você digitou certo?");
    } else {
      setPixKeyError("");
    }

    if (!amountError && pixKey) {
      setIsDisabled(true);

      setStatus(dispatch(payments.createWithdraw(formattedAmount, pixKey)));
    }
  }

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <>
      <Menu />
      <div className="background">
        <div className="button-group panel">
          <img className="logo" alt="logo" src={logo}></img>
          <form onSubmit={handleSubmit}>
            <span className="span">
              {token && !isDisabled && (
                <div className="form-panel">
                  <div className="container-form">
                    <div className="row">
                      <h2 style={{ textAlign: "center" }}>
                        Quanto quer Sacar?
                      </h2>
                      <div className="input-group input-group-icon">
                        <CurrencyInput amount={amount} setAmount={setAmount} />
                        <div className="input-icon">
                          <i className="fa-solid fa-money-bill-transfer"></i>
                        </div>
                      </div>
                      {amountError && (
                        <span style={{ color: "red", maxWidth: "340px" }}>
                          {amountError}
                        </span>
                      )}
                    </div>

                    <h2 style={{ textAlign: "center" }}>Qual sua Chave Pix?</h2>
                    <div className="input-group input-group-icon">
                      <input
                        type="text"
                        placeholder="Chave Pix"
                        value={pixKey}
                        onChange={(e) => setPixKey(e.target.value)}
                        // onBlur={() => validatePixKey()}
                        // onFocus={() => {
                        //   setPixKey("");
                        //   setPixKey(pixKey.trim());
                        // }}
                      />
                      <div className="input-icon">
                        <i className="fa fa-key"></i>
                      </div>
                      {pixKeyError && (
                        <span style={{ color: "red", maxWidth: "340px" }}>
                          {pixKeyError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                // <table style={{ backgroundColor: "rgb(0,0,0, 0.4)" }}>
                //   <tbody>
                //     {/* Valor do saque */}
                //     <tr>
                //       <td>
                //         <td style={{ textAlign: "center" }}>
                //           Digite o valor que deseja sacar:
                //         </td>
                //       </td>
                //     </tr>
                //     <tr>
                //       <td style={{ textAlign: "center" }}>
                //         {amountError && (
                //           <p style={{ color: "red", fontSize: "12px" }}>
                //             {amountError}
                //           </p>
                //         )}
                //         <span
                //           className="currencyinput"
                //           style={{
                //             display: "inline",
                //             border: "1px inset #ccc",
                //             backgroundColor: "white",
                //             color: "black",
                //           }}
                //         >
                //           R$
                //           <input
                //             placeholder="Digite sua chave pix.."
                //             value={amount}
                //             onChange={(e) => setAmount(e.target.value)}
                //             style={{
                //               border: "0",
                //               width: "170px",
                //               marginBottom: "25px",
                //             }}
                //             type="text"
                //           />
                //         </span>
                //       </td>
                //     </tr>

                //     {/* Chave Pix */}
                //     <tr>
                //       <td>
                //         <td style={{ textAlign: "center" }}>
                //           Digite sua chave Pix:
                //         </td>
                //       </td>
                //     </tr>
                //     <tr>
                //       <td style={{ textAlign: "center" }}>
                //         {emptyPixError && (
                //           <p style={{ color: "red", fontSize: "12px" }}>
                //             {emptyPixError}
                //           </p>
                //         )}
                //         <span
                //           className="currencyinput"
                //           style={{
                //             display: "inline",
                //             border: "1px inset #ccc",
                //             backgroundColor: "white",
                //             color: "black",
                //           }}
                //         >
                //           <input
                //             placeholder="Digite sua chave Pix..."
                //             value={pixKey}
                //             onChange={(e) => setPixKey(e.target.value)}
                //             style={{ border: "0", width: "198px" }}
                //             type="text"
                //           />
                //         </span>
                //       </td>
                //     </tr>
                //   </tbody>
                // </table>
              )}
              {token && !payment.pixQrCode && !isDisabled && (
                <div className="login-logout">
                  <section className="western-button-wrapper">
                    <div className="western-button-container">
                      <button
                        onClick={handleSubmit}
                        className="western-button"
                        disabled={isDisabled}
                      >
                        Sacar
                      </button>
                    </div>
                  </section>
                </div>
              )}
            </span>
          </form>
          {token && status && (
            <>
              <p style={{ color: "white", width: "300px" }}>
                Seu saque foi requisitado. Dentro de algumas horas o dinheiro
                vai cair na sua conta!
              </p>
              <p style={{ color: "white", width: "300px" }}>
                Caso tenha cometido algum erro, entre em contato com a gente
                pelo WhatsApp!
              </p>
            </>
          )}
        </div>
        <WhatsAppButton></WhatsAppButton>
      </div>
      <MobileMenu />
    </>
  );
};

export default WithdrawClient;
