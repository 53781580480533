import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timer: 20,
    rounds: {
      list: []
    },
    state: {
      id: 5686,
      next_to_play_id: 3,
      p1: {
        id: 92,
        name: 'Fabioalxk',
        ranking_score: 1442,
        game_score: 0,
        round_score: 0,
        cards: [
          {
            value: 'J',
            suit: '♠',
            flipped: false,
            played: false
          },
          {
            value: '2',
            suit: '♦',
            flipped: false,
            played: false
          },
          {
            value: '7',
            suit: '♦',
            flipped: false,
            played: false
          }
        ],
        first_play: null,
        second_play: null,
        third_play: null,
        last_third_play: null
      },
      p2: {
        id: 3,
        name: 'TruqueiroRaiz',
        ranking_score: 1601,
        game_score: 0,
        round_score: 1,
        cards: [
          {
            value: '2',
            suit: '♠',
            flipped: false,
            played: false
          },
          {
            value: '3',
            suit: '♠',
            flipped: false,
            played: false
          },
          {
            value: '4',
            suit: '♠',
            flipped: false,
            played: false
          },
        ],
        first_play: null,
        second_play: null,
        third_play: null,
        last_third_play: null
      },
      manilha: {
        value: '6',
        suit: '♥'
      },
      round_points: 1,
      first_round_winner: 3,
      truco_challenger_id: null,
      truco_challenge_running: 0,
      round_winner: null
    },
    id: 5686
};

const reducers = {
  updateGame: (state, action) => {
    state.state = action.payload;
  },
  updateCard: (state, action) => {
    const { value, suit, player } = action.payload;
    if (player === 1) {
      const index = state.state.p1.cards.findIndex((card) => (card.value === value && card.suit === suit));
      state.state.p1.cards[index] = {...state.state.p1.cards[index], played: true};
      if (!state.state.p1.first_play) {
        state.state.p1.first_play = `${value}${suit}`;
      } else if (!state.state.p1.second_play) {
        state.state.p1.second_play = `${value}${suit}`
      } else {
        state.state.p1.third_play = `${value}${suit}`
      }
    }
    if (player === 2) {
      const index = state.state.p2.cards.findIndex((card) => (card.value === value && card.suit === suit));
      state.state.p2.cards[index] = {...state.state.p2.cards[index], played: true};
      if (!state.state.p2.first_play) {
        state.state.p2.first_play = `${value}${suit}`;
      } else if (!state.state.p2.second_play) {
        state.state.p2.second_play = `${value}${suit}`;
      } else {
        state.state.p2.third_play = `${value}${suit}`;
      }
    }
  },
  updateFlipped: (state) => {
    state.flipped = !state.flipped;
  },
  setGameId: (state, action) => {
    state.id = action.payload;
  },
  timerReset: (state) => {
    state.timer = 20;
  },
  timerDisable: (state) => {
    state.timer = '--';
  },
  timerTick: (state) => {
    state.timer = state.timer - 1;
  },
  timerTrucoReset: (state) => {
    state.timerTruco = 15;
  },
  timerTrucoDisable: (state, action) => {
    state.timerTruco = action.payload;
  },
  timerTrucoTick: (state) => {
    state.timerTruco = state.timerTruco - 1;
  },
  setInitialCardState: (state, action) => {
    state.state.p1.cards = [
      {
        value: action.payload.cards1[0].value,
        suit: transformSuit(action.payload.cards1[0].suit),
        flipped: false,
        played: false
      },
      {
        value: action.payload.cards1[1].value,
        suit: transformSuit(action.payload.cards1[1].suit),
        flipped: false,
        played: false
      },
      {
        value: action.payload.cards1[2].value,
        suit: transformSuit(action.payload.cards1[2].suit),
        flipped: false,
        played: false
      }
    ];

    state.state.p2.cards = [
      {
        value: action.payload.cards2[0].value,
        suit: transformSuit(action.payload.cards2[0].suit),
        flipped: false,
        played: false
      },
      {
        value: action.payload.cards2[1].value,
        suit: transformSuit(action.payload.cards2[1].suit),
        flipped: false,
        played: false
      },
      {
        value: action.payload.cards2[2].value,
        suit: transformSuit(action.payload.cards2[2].suit),
        flipped: false,
        played: false
      }
    ];
  }
};

const transformSuit = (suit) => {
  if (suit == 1) {
      return '♦';
  }
  if (suit == 2) {
    return '♠';
  }
  if (suit == 3) {
    return '♥';
  }
  if (suit == 4) {
    return '♣';
  }
  return '♦';
}

export const gameSimulator = createSlice({
  name: 'gameSimulator',
  initialState,
  reducers,
});
