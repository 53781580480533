import toast from "react-hot-toast";
import { push } from "redux-first-history";
import { MILISECONDS_TO_FETCH_GAME_STATE } from "../../utils/constants";
import * as http from "../../utils/requests";
import { game } from "../reducers/game";
import store from "../store";
import { sleep } from "../../utils/utils";
import shuffleSound from '../../assets/voices/shuffle.mp3';
import cantPlayCardSound from '../../assets/voices/cantPlayCard.mp3';
import playSound from '../../assets/voices/play.mp3';

export const {
  updateGame,
  updateCard,
  updateFlipped,
  setGameId,
  timerDisable,
  timerReset,
  timerTick,
  timerTrucoDisable,
  timerTrucoReset,
  timerTrucoTick,
} = game.actions;

const shuffleSoundAction = new Audio(shuffleSound);
const cantPlayCardSoundAction = new Audio(cantPlayCardSound);
const playCardSound = new Audio(playSound);

export const fetchGameState = () => async (dispatch) => {
  try {
    const response = await http.get("games/current");
    if (response.status === 200) {
      const { game } = store.getState();
      const { timer, state } = game;
      const { data } = response;

      // GAME STATE É O ESTADO PRESENTE
      // RESPONSE DATE É O ESTADO FUTURO

      if (data) {
        if (timer === undefined) {
          await dispatch(timerDisable());
        }

        // GAME END
        if (data.p1.game_score >= 12) {
          toast.success("Vitória!! Muito bem jogado!");
          dispatch(updateGame(data));
          await sleep(8000);
          dispatch(push("/"));
          return;
        } else if (data.p2.game_score >= 12) {
          toast.error("Derrota!! Mais sorte na próxima...");
          dispatch(updateGame(data));
          await sleep(8000);
          dispatch(push("/"));
          return;
        } else {
          setTimeout(
            () => dispatch(fetchGameState()),
            MILISECONDS_TO_FETCH_GAME_STATE
          );
        }

        const newWinner =
          !game.state.round_winner && data.round_winner ? true : false;

        const empate =
          data.p1.round_score - state.p1.round_score === 1 &&
          data.p2.round_score - state.p2.round_score === 1;
        dispatch(updateGame(data));

        if (
          !newWinner &&
          !empate &&
          state.p1.round_score < data.p1.round_score
        ) {
          toast.success("Você ganhou a rodada. Torna!");
          await dispatch(timerDisable())
          dispatch(updateGame(data));
          await sleep(2000);
        }

        if (
          !newWinner &&
          !empate &&
          state.p2.round_score < data.p2.round_score
        ) {
          await dispatch(timerDisable())
          dispatch(updateGame(data));
          await sleep(2000);
        }

        if (!newWinner && empate) {
          await dispatch(timerDisable())
          dispatch(updateGame(data));
          await sleep(2000);
        }

        if (newWinner) {
          if (state.p1.id === data.round_winner) {
            toast.success("Você ganhou a melhor de três!");
            await dispatch(timerDisable())
            shuffleSoundAction.play();
            await sleep(3200);
            shuffleSoundAction.play();
          } else {
            shuffleSoundAction.play();
            await sleep(3200);
            toast.error("Você perdeu a melhor de três...");
            shuffleSoundAction.play();
          }
        }

        dispatch(updateGame(data));

        if (timer === undefined) {
          dispatch(timerReset());
          dispatch(timerStart());
        }
        
        if (state.truco_challenge_running === 0 && data.truco_challenge_running === 1) {
          dispatch(timerTrucoReset());
          dispatch(timerReset());
        }
        
        if (state.truco_challenge_running === 1 && state.truco_challenger_id === state.p1.id && data.truco_challenger_id === state.p2.id) {
          dispatch(timerTrucoReset());
          dispatch(timerReset());
        }
        
        if (state.truco_challenge_running === 1 && state.truco_challenger_id === state.p2.id && data.truco_challenger_id === state.p1.id) {
          dispatch(timerTrucoReset());
          dispatch(timerReset());
        }

        if (!state.p1.first_play && data.p1.first_play) {
          dispatch(timerDisable());
          dispatch(timerReset());
        }

        if (!state.p1.second_play && data.p1.second_play) {
          dispatch(timerDisable());
          dispatch(timerReset());
        }

        if (!state.p1.third_play && data.p1.third_play) {
          dispatch(timerDisable());
          dispatch(timerReset());
        }

        if (!state.p2.first_play && data.p2.first_play) {
          dispatch(timerDisable());
          dispatch(timerReset());
        }

        if (!state.p2.second_play && data.p2.second_play) {
          dispatch(timerDisable());
          dispatch(timerReset());
        }

        if (!state.p2.third_play && data.p2.third_play) {
          dispatch(timerDisable());
          dispatch(timerReset());
        }
      }
    }
  } catch (error) {
    if (error.response && error.response.data) {
      if (error.response.data.detail === "Nenhum jogo ativo para este jogador") {
        return dispatch(push("/"));
      }
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro ao recuperar estado do jogo.");
    }
  }
};

export const playCard = (value, suit) => async (dispatch) => {
  const { game } = store.getState();
  const { flipped } = game;

  try {
    const response = await http.post("games/current/plays", {
      card_played: value + suit,
      flipped,
    });
    playCardSound.play();
    if (response.status === 200) {
      dispatch(updateCard({ value, suit }));
      if (flipped) {
        dispatch(updateFlipped());
      }
    }
  } catch (error) {
    cantPlayCardSoundAction.play();
    // if (error.response && error.response.data) {
    //   toast.error(error.response.data.detail);
    // } else {
    //   toast.error("Erro ao jogar carta...");
    // }
  }
};

export const timerCount = () => async (dispatch) => {
  const { game } = store.getState();
  const { timer, state } = game;

  if (timer && timer !== '--' && timer > 0 && state.truco_challenge_running === 0) {
    dispatch(timerTick());
  }
  setTimeout(() => dispatch(timerCount()), 1200);
};

export const timerTrucoCount = () => async (dispatch) => {
  const { game } = store.getState();
  const { timerTruco, state } = game;

  if (timerTruco !== undefined && timerTruco > 0 && state.truco_challenge_running === 1) {
    dispatch(timerTrucoTick());
  } else {
    dispatch(timerTrucoDisable(undefined));
  }
  setTimeout(() => dispatch(timerTrucoCount()), 1200);
};

export const timerStart = () => async (dispatch) => {
  const { game } = store.getState();
  const { timer } = game;

  await sleep(2000);
  dispatch(timerCount());
  dispatch(timerTrucoCount());
};

export const truco = () => async (dispatch) => {
  try {
    const response = await http.post("games/current/truco");
    if (response.status === 200) {
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro ao pedir truco.");
    }
  }
};

export const trucoAccept = () => async (dispatch) => {
  try {
    const response = await http.post("games/current/truco-accept");
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro ao aceitar o truco.");
    }
  }
};

export const trucoRaise = () => async (dispatch) => {
  try {
    const response = await http.post("games/current/truco-raise");
    if (response.status === 200) {
      toast.success("Aumento a aposta!");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro ao aumentar a aposta.");
    }
  }
};

export const trucoRefuse = () => async (dispatch) => {
  try {
    const response = await http.post("games/current/truco-refuse");
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro ao recusar o truco.");
    }
  }
};

// export const updateTimer = (timeLeft) => async (dispatch) => {
// update do tempo atual
// espera 1 segundo
// verifica se tempo acabou
// se tempo acabou, mostra o aviso de tempo esgotado
// impedir jogador de fazer novas jogadas
// espera atualização do estado no backend
// atualiza tempo atual dispatch(updateTimer({ timeLeft }));
// };

// export const postNewSearch = (keyword) => async (dispatch) => {
//   try {
//     const response = await http.post('crawl', { keyword });
//     if (response.status === 200) {
//       const { id } = response.data;

//       dispatch(addSearch({ id, keyword }));
//       // dispatch(fetchUrlsById(id));
//     }
//   } catch (error) {
//     toast('Error Searching.');
//   }
// };
