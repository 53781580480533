import React from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { history } from "../redux/store";

import { Route, Routes } from "react-router-dom";

import Home from "../components/Home";
import Game from "./game/Game";
import Login from "./login/Login";
import EsqueciSenha from "./esqueci-senha/EsqueciSenha";
import RedefinirSenha from "./redefinir-senha/RedefinirSenha";
import NewAccount from "./newAccount/NewAccount";
import Payment from "./payment/Payment";
import Ranking from "./ranking/Ranking";
import Unsubscribe from "./unsubscribe/Unsibscribe";
import WithdrawDashboard from "./withdrawDashboard/WithdrawDashboard";
import CarrinhosAbandonados from "./carrinhosAbandonados/CarrinhosAbandonados";
import WithdrawClient from "./withdrawClient/WithdrawClient";
import GameSimulator from "./game-simulator/GameSimulator";
import Profile from "./profile/Profile";
import Download from "./download-app/Download";
import ChatApp from "./chatApp/ChatApp";

const PageRoutes = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/new-account" element={<NewAccount />} />
        <Route path="login" element={<Login />} />
        <Route path="esqueci-senha" element={<EsqueciSenha />} />
        <Route path="redefinir-senha" element={<RedefinirSenha />} />
        <Route path="game" element={<Game />} />
        <Route path="gameSimulator" element={<GameSimulator />} />
        <Route path="payment" element={<Payment />} />
        <Route path="ranking" element={<Ranking />} />
        <Route path="profile" element={<Profile />} />
        <Route path="download" element={<Download />} />
        <Route path="withdrawDashboard" element={<WithdrawDashboard />} />
        <Route path="carrinhosAbandonados" element={<CarrinhosAbandonados />} />
        <Route path="withdraw" element={<WithdrawClient />} />
        <Route path="desinscrever" element={<Unsubscribe />} />
        <Route path="chatApp" element={<ChatApp />} />
      </Routes>
    </Router>
  );
};

export default PageRoutes;
