import axios from 'axios';
import { decrementLoading, incrementLoading } from '../redux/actions/communication';
import store from '../redux/store';
import { SERVICE_HTTP_ADDRESS } from './constants';
import { logout } from "../redux/actions/session";

/* -------------------------------------------------------------------------- */
/*                         HTTP Requests Configuration                        */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Default Values ----------------------------- */

axios.defaults.baseURL = SERVICE_HTTP_ADDRESS;
// axios.defaults.headers = { Authorization: `Bearer ${store.getState().session.token}` }

/* ------------------------------ Interceptors ------------------------------ */

axios.interceptors.request.use((config) => {
  // store.dispatch(incrementLoading());
  config.headers.Authorization  =  `Bearer ${store.getState().session.token}`;
  return config;
});

axios.interceptors.response.use((response) => {
  store.dispatch(decrementLoading());
  return response;
}, (error) => {
  store.dispatch(decrementLoading());
  if (error.response.status === 401) {
    store.dispatch(logout())		
    return
  }
  return Promise.reject(error);
});

/* ------------------------------ Http Requests ----------------------------- */

export function get(url, id) {
  const urlTemplate = (id && `${url}/${id}`) || url;

  return new Promise((resolve, reject) => axios.get(urlTemplate)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    }));
}

export function post(url, params) {
  return new Promise((resolve, reject) => axios.post(url, params)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    }));
}

export function put(url, params) {
  return new Promise((resolve, reject) => axios.put(url, params)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    }));
}

export function del(url, id) {
  return new Promise((resolve, reject) => axios.delete(url, id)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    }));
}
